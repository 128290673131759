<template>
  <div class="position-relative">
    <div v-for="(value, key) in reorderedSections" :key="key">
      <CollapsableSection v-if="sectionHasItems(key, value)" class="mb-4" :title="titleGetter(key, value)">
        <div v-if="value.type === 'text' || isHtml(value)">
          <ContentSeeMore :text="value.body || value" />
        </div>
        <div v-else-if="key === 'spotify_link'">
          <ClientOnly>
            <div v-for="(link, index) in musicLinks" :key="index">
              <div class="music-label" v-if="get(link, 'label')">
                {{ get(link, 'label') }}
              </div>
              <iframe
                v-if="get(link, 'link').includes('spotify.com/')"
                style="border-radius: 12px"
                :src="getSpotifyEmbedLink(get(link, 'link'))"
                class="mb-2"
                width="100%"
                height="80"
                frameBorder="0"
                :allowfullscreen="false"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              ></iframe>
              <iframe
                v-else-if="isYoutubeVideoLinkValid(get(link, 'link'))"
                style="border-radius: 8px"
                class="mb-2"
                width="100%"
                height="120"
                :src="`https://www.youtube.com/embed/${getYoutubeVideoId(get(link, 'link'))}?rel=0&fs=0`"
                title="YouTube player"
                frameborder="0"
                :allowfullscreen="false"
                allow="clipboard-write; encrypted-media; web-share"
              >
              </iframe>
            </div>
          </ClientOnly>
        </div>
        <div v-else-if="key === 'visual_collections_page'">
          <InlineGallery :is-editor="false" :parent-type="parentType" :parent-id="world?.id" />
        </div>

        <div v-else-if="key === 'featuredin'">
          <FeaturedIn :value="value" />
        </div>
        <div v-else-if="key === 'colors'">
          <Colors :value="value" />
        </div>
        <div v-else-if="key === 'about'">
          <About :value="value" />
        </div>
        <div v-else-if="value.type === 'text'">
          <div v-html="sanitizeHtml(value.body)" />
        </div>

        <div v-else-if="key === 'featured_collections'">
          <Grid v-if="folders.length" :lg="3" :md="3" :sm="2" :scrollSm="false">
            <FolderCard v-for="(folder, index) of folders" :key="index" class="grid-item" :folder="folder" />
          </Grid>
          <div v-else class="no-data">No folders</div>
        </div>

        <div v-else-if="key === 'character_worlds'">
          <Grid v-if="charlistings.length" :lg="3" :md="3" :sm="2" :scrollSm="false">
            <CharacterCard
              v-for="(character, index) of charlistings"
              :key="index"
              class="grid-item"
              :character="character"
              :char-comments-count="get(charCommentsCount, `${get(character, 'id')}.comments_count`) || 0"
            />
          </Grid>
          <div v-else class="no-data">No characters</div>
        </div>
        <div v-else-if="key === 'subworlds' || key === 'included_locations'">
          <div v-if="!isEmpty(subworlds)">
            <story-world-locations-card
              :subworlds="route.name === 'world-draft-details' ? sameWorlds : subworlds"
              :allworlds="allWorlds"
              @after-reaction="onReaction"
            />
          </div>
          <div v-if="!isEmpty(includedLocations)">
            <included-world :value="includedLocations" />
          </div>
        </div>
      </CollapsableSection>
    </div>
  </div>
</template>

<script lang="ts" setup>
import InlineGallery from '@/shared/components/Gallery/InlineGallery.vue';
import CollapsableSection from './CollapsableSection.vue';
import Colors from './Colors.vue';
import FeaturedIn from './FeaturedIn.vue';
import About from './About.vue';
import IncludedWorld from './IncludedWorld.vue';
import { ImageCollection, SocialSpace, WorldsInfo } from '@/shared/types/static-types';
import { possibleExtraSectionsLabelMap, possibleSectionsLabelMap } from '@/shared/statics/story-world';
import constants, { unreorderableCreatorKeys } from '@/shared/statics/constants';
import StoryWorldLocationsCard from '@/shared/components/StoryWorldLocationsCard.vue';
import ContentSeeMore from '@/shared/components/ContentSeeMore.vue';
import { getAllStoryWorlds } from '@/shared/actions/worlds';
import { getUserCharacters } from '@/shared/actions/characters';
import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import FolderCard from '@/shared/components/storage/FolderCard.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import { sanitizeHtml } from '@/shared/utils/html';
import { getCommentsCount } from '@/shared/actions/comments';
import { isYoutubeVideoLinkValid } from '@/shared/utils/string';

const { user } = authStore();

const props = defineProps({
  world: {
    type: Object,
    default: () => ({}),
  },
  worldType: {
    type: String,
    default: '',
  },
});

const world = toRef(props, 'world');
const allWorlds = ref([] as any[]);
const allCharacters = ref([] as any[]);
const sameWorlds = ref([] as any[]);
const sameCharacters = ref([] as any[]);
const route = useRoute();
const charCommentsCount = ref({});

const titleGetter = (key: string, value: any) => {
  return possibleSectionsLabelMap[key] || value.title || '' || possibleExtraSectionsLabelMap[key];
};

const sectionHasItems = (key: any, value: any) => {
  if (key === 'visual_collections_page' && isEmpty(populatedVisualCollections.value)) {
    return false;
  }
  if (Array.isArray(value) && typeof value === 'object' && key === 'about') {
    const newVal = value.filter((item: any) => item.value !== '');
    if (isEmpty(newVal)) return false;
  }
  if (key === 'extra') return false;
  if (key === 'about' && !value) return false;
  if (key === 'included_locations' && !isEmpty(subworlds.value)) return false;
  if (!value) return false;
  if (!Array.isArray(value) && typeof value !== 'object' && value) return true;
  if (Array.isArray(value) && value.length) return true;
  if (typeof value === 'object' && Object.keys(value).length > 0) {
    if (value.type === 'text' && !value.body) {
      return false;
    }
    return true;
  }

  return false;
};
const includedLocations = computed(() => {
  return world.value.included_locations;
});

const possibleSections = computed(() => {
  return omit(world?.value, unreorderableCreatorKeys as string[]);
});

const charlistings = computed(() => {
  const worldUser = world.value.user.username;
  const { user } = authStore();
  const currentUser = user.value.username;

  if (worldUser === currentUser) {
    return world.value.character_worlds;
  }

  return world.value.character_worlds.filter((ch: any) => ch.info.privacy !== 'M' && ch.info.privacy !== 'U');
});

const fetchCharsCommentCounts = async () => {
  const resp = await getCommentsCount(map(charlistings.value, 'id') as string[], constants.commentedAsTypes.CHARACTER);
  charCommentsCount.value = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
};
watch(
  charlistings,
  () => {
    fetchCharsCommentCounts();
  },
  { immediate: true, deep: true }
);
const folders = computed(() => {
  return world.value.featured_collections;
});

const fetchStoryWorlds = async () => {
  try {
    const response = await getAllStoryWorlds('story', user.value.id, 1);
    allWorlds.value = response.results;

    sameWorlds.value = allWorlds.value.filter((world: any) => {
      return subworlds.value.includes(world.id);
    });
  } catch (_e) {}
};

const fetchCharacters = async () => {
  try {
    const response = await getUserCharacters(user.value.id, null);
    allCharacters.value = response;

    sameCharacters.value = allCharacters.value.filter((world: any) => {
      return charlistings.value.includes(world.id);
    });
  } catch (_e) {}
};

const subworlds = computed(() => {
  return world.value.subworlds;
});

const parentType = computed(() => {
  if (route.name === 'world-draft-details') return 'world_draft';
  return 'world';
});

const hasValue = (value: any, key: any) => {
  if (key === 'extra') return false;
  return value || value === '';
};

const reorderedSections = computed(() => {
  // Move description to top in default ordering
  const defaultPossibleSections = Array.from(Object.keys(possibleSections.value));
  const index = defaultPossibleSections.indexOf('description');
  defaultPossibleSections.unshift(defaultPossibleSections.splice(index, 1)[0]);

  const order: string[] = world?.value?.extra.order || defaultPossibleSections;
  const reorderedSections = order.reduce((acc: WorldsInfo, current) => {
    if (!hasValue(possibleSections.value[current], '') && !hasValue(possibleSections.value.extra[current], '')) {
      return acc;
    }
    return {
      ...acc,
      [current]: !hasValue(possibleSections.value[current], '')
        ? possibleSections.value.extra[current]
        : possibleSections.value[current],
    };
  }, {} as WorldsInfo);
  return reorderedSections;
});

const onReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  const index =
    route.name === 'world-draft-details'
      ? sameWorlds.value.findIndex((social: any) => social.id === event.social.id)
      : subworlds.value.findIndex((social: any) => social.id === event.social.id);
  route.name === 'world-draft-details'
    ? (sameWorlds.value[index] = { ...event.social, ...get(event.reactResp, 'updatedReactionsData', {}) })
    : (subworlds.value[index] = { ...event.social, ...get(event.reactResp, 'updatedReactionsData', {}) });
};

const visualCollections = computed(() => {
  return world?.value?.visual_collections_page?.results || [];
});

const populatedVisualCollections = computed(() => {
  return visualCollections.value.filter((coll: ImageCollection) => coll?.images_page?.count || 0) || [];
});

const isHtml = (content: string) => {
  return `${content}`.includes('<');
};

onMounted(() => {
  fetchStoryWorlds();
  fetchCharacters();
});

const musicLinks = computed(() => {
  const links = world.value?.extra?.spotify_link || [];
  return links;
});
const getSpotifyEmbedLink = (link: any) => {
  return link.replace(/^https:\/\/\w+.spotify.com/gi, 'https://open.spotify.com/embed') + '?utm_source=generator';
};
const getYoutubeVideoId = (link: any) => {
  const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  const r = link.match(rx);
  return r ? r[1] : '';
};
</script>

<style lang="sass" scoped>
.music-label
  font-weight: bold
  font-size: 16px
  margin-bottom: 5px
  color: #000
.dark .music-label
  color: white
</style>
