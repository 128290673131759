<template>
  <div class="about">
    <div v-for="(item, index) of about" :key="index">
      <div v-if="hasValue(item)" class="one-about d-flex align-items-center">
        <div class="key">{{ toTitleSpaceCase(item.key) }}</div>
        <div class="value d-flex align-items-center justify-content-center flex-grow-1">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toTitleSpaceCase } from '@/shared/utils/string';

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
});

const about = toRef(props, 'value');

const hasValue = (about: any) => {
  return about.value;
};
</script>

<style lang="sass" scoped>
.about
  .one-about
    margin-top: 10px
    @media(max-width: 500px)
      flex-direction: column
      .key, .value
        width: 100% !important
      .key
        margin-bottom: 5px
    .value
      background: #fff
      border-radius: 20px
      padding: 5px 7px
      width: calc(100% - 130px)
    .key
      width: 130px
      font-weight: bold
@media(max-width: 600px)
  .key
    width: 120px !important
</style>
