<template>
  <div class="collapsable-section">
    <ion-accordion-group ref="accordionGroup" :value="accordionValue">
      <ion-accordion value="first">
        <ion-item slot="header" color="light" :class="{ dark }">
          <ion-label>{{ title }}</ion-label>
        </ion-item>
        <div slot="content" class="ion-padding content">
          <slot />
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </div>
</template>

<script lang="ts" setup>
import { storyWorldStore, SectionsCollapsed } from '@/shared/pinia-store/storyworld';
import { mainStore } from '@/shared/pinia-store/main';

const props = defineProps({
  title: {
    type: String,
  },
  key: {
    type: Boolean,
  },
});

const title = toRef(props, 'title');

const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const { sectionsCollapsed } = storyWorldStore();
const accordionGroup = ref(null);

const accordionValue = computed(() => (!sectionsCollapsed.value ? 'first' : undefined));

const collapse = (value: number) => {
  if (!accordionGroup.value) {
    return;
  }

  const nativeEl = (accordionGroup.value as any).$el;

  nativeEl.value = value ? 'first' : undefined;
};

watch(sectionsCollapsed, (value: SectionsCollapsed) => {
  collapse(value);
});

onMounted(() => {
  collapse(sectionsCollapsed.value);
});
</script>

<style lang="sass" scoped>
.collapsable-section
  border-radius: 20px
  --background: #E6E6E6
  --ion-color-base: #E6E6E6
  ion-item
    --inner-padding-end: 15px !important
    --inner-padding-start: 5px !important
    --inner-padding-top: 5px !important
    --inner-padding-bottom: 5px !important
    --ion-color-base: #E6E6E6 !important
    font-size: 24px
    font-weight: 800
    color: #333333
    --background: #E6E6E6
    &.dark
      --ion-color-base: #17074C !important
  overflow: hidden
  .content
    background: #E6E6E6
    padding: 5px 22px 15px 22px
    font-size: 16px
    color: #333333
</style>
